import './App.css';
import RegForm from './components/reg-form';

function App() {
  return (
    <div className="App">
      <RegForm />
    </div>
  );
}

export default App;
