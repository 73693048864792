import './style.css';
import {useState, useEffect} from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import validator from 'validator';
import classNames from 'class-names';

function App() {
  const [name, setName] = useState("");
  const [age, setAge] = useState(6);
  const [parentName, setParentName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [ageErr, setAgeErr] = useState();
  const [parentNameErr, setParentNameErr] = useState();
  const [phoneErr, setPhoneErr] = useState();
  const [emailErr, setEmailErr] = useState();


  useEffect(() => {
    if (localStorage.getItem('token')) {
     window.location.replace(`https://quest.itgen.io/magic/${localStorage.getItem('token')}`);
    }
    axios.get(`https://get.geojs.io/v1/ip/country.json`)
      .then((res, err) => {
        if (err) {
          console.warn(err);
          setCountry("BY");
          return;
        }
        const data = res.data;
        if (!data || !data.country) {
          setCountry("BY");
          return;
        }
        setCountry(data.country);
      })
  })

  function sendData(data) {
    axios.post(`https://quest.itgen.io/server/register/`, data, {headers: {'Content-Type': 'application/json'}})
    .then((res, err) => {
      if (err) {
        console.warn(err);
        return;
      }
      const data = res.data;
      console.log(data);
      if (data.token) {
        localStorage.setItem('token', data.token);
        window.location.replace(`https://quest.itgen.io/magic/${data.token}`);
      }
    })
  }

  function contains(arr, elem) {
    return arr.indexOf(elem) !== -1;
  }

  const checkValidity = (value, validation) => {
    const validationObject = {
        isValid: true,
        errorMessage: ''
    };

    if (contains(validation, "required")) {
      validationObject.isValid = !validator.isEmpty(value.toString().trim());
      validationObject.errorMessage = validationObject.isValid ? '' : 'Поле должно быть заполнено';
      if (!validationObject.isValid) return validationObject;
    }
    if (contains(validation, "phone")) {
      validationObject.isValid = validator.isMobilePhone(value.trim());
      validationObject.errorMessage = validationObject.isValid ? '' : 'Неверный формат ввода';
      if (!validationObject.isValid) return validationObject;
    }
    if (contains(validation, "age")) {
      validationObject.isValid = +(value) >= 6 && +(value) <= 17;
      validationObject.errorMessage = validationObject.isValid ? '' : 'Возраст участника должен быть от 6 до 17 лет';
      if (!validationObject.isValid) return validationObject;
    }
    if (contains(validation, "email")) {
      validationObject.isValid = validator.isEmail(value.trim());
      validationObject.errorMessage = validationObject.isValid ? '' : 'Неверный формат ввода';
      if (!validationObject.isValid) return validationObject;
    }
    return validationObject;
  }

  const isFormValid = () => {
    let isValid = true;
    const nameValid = checkValidity(name, ['required']);
    const ageValid = checkValidity(age, ['required', 'age']);
    const parentNameValid = checkValidity(parentName, ['required']);
    const phoneValid = checkValidity(phone, ['required', 'phone']);
    const emailValid = checkValidity(email, ['required','email']);
    if (!nameValid.isValid) {
      isValid = false;
    }
    if (!ageValid.isValid) {
      isValid = false;
    }
    if (!parentNameValid.isValid) {
      isValid = false;
    }
    if (!phoneValid.isValid) {
      isValid = false;
    }
    if (!emailValid.isValid) {
      isValid = false;
    }
    setNameErr(nameValid.errorMessage);
    setAgeErr(ageValid.errorMessage);
    setPhoneErr(phoneValid.errorMessage);
    setParentNameErr(parentNameValid.errorMessage);
    setEmailErr(emailValid.errorMessage);

    return isValid;

  }

  const handlerSubmit = e => {
    e.preventDefault();
    if (isFormValid()) {
      sendData(JSON.stringify({name, parentName, age, email, phone}));
    }
  }

  return (
    <div className="reg-form-page">
      <form action="/regcompleted" method="post" id="reg" onSubmit={handlerSubmit}>
        <span className="head">Принять участие</span>

        <div className={classNames("group", nameErr && "err")}>
          <span className="title">Имя и фамилия участника</span>
          <input value={name} type="text" id="name" placeholder="Константин Константиновсий" required onChange={e => setName(e.target.value)}/>
          <span className="error-message">{nameErr}</span>
        </div>

        <div className={classNames("group", ageErr && "err")}>
          <span className="title">Возраст</span>

          <input value={age} type="number" min="6" max="17" step="1" id="age" placeholder="Сколько лет участнику?" required onChange={e => setAge(e.target.value)}/>
          <span className="error-message">{ageErr}</span>
        </div>

        <div className={classNames("group", parentNameErr && "err")}>
          <span className="title">Имя и фамилия родителя</span>
          <input value={parentName} type="text" name='parent' id="parent" placeholder="Галина Константиновская" required onChange={e => setParentName(e.target.value)}/>
          <span className="error-message">{parentNameErr}</span>
        </div>

        <div className={classNames("group", phoneErr && "err")}>
          <span className="title">Телефон родителя</span>  
          <PhoneInput 
          defaultCountry={country}
          international={true}
          withCountryCallingCode={true}
          value={phone}
          onChange={setPhone}
          />
          <span className="error-message">{phoneErr}</span>
        </div>

        <div className={classNames("group", emailErr && "err")}>
          <span className="title">Email родителя</span>
          <input value={email} type="text" id="email" placeholder="myMail@gmail.com" required onChange={e => setEmail(e.target.value)}/>
          <span className="error-message">{emailErr}</span>
        </div>

        <input type="submit" value="Принять участие бесплатно" id="sendForm"></input>
      </form>
    </div>
  );
}

export default App;
